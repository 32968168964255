/* @import url("https://fonts.googleapis.com/css?family=Montserrat:400|Inconsolata:400"); */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arizonia&family=Oswald:wght@200&display=swap");
.font_cursive {
  font-family: "Arizonia";
}
@import url("https://fonts.googleapis.com/css2?family=Smooch&display=swap");
.font_cursive2 {
  font-family: "Cedarville Cursive", cursive;
}
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
.orbitron {
  font-family: "Orbitron", sans-serif;
}

:root {
  /* COLORS  */
  /* primary */
  --primary-0: #f0efff;
  --primary-50: #d8d6ff;
  --primary-100: #c1beff;
  --primary-200: #aaa5ff;
  --primary-300: #938dff;
  --primary-400: #7b74ff;
  /* main */
  --primary-500: #645cff;
  --primary-600: #554ed9;
  --primary-700: #4640b3;
  --primary-800: #37338c;
  --primary-900: #282566;
  --primary-1000: #191740;
  --primary-1100: #0a0919;
  /* grey */
  --grey-0: #fafbfc;
  --grey-50: #f2f4f8;
  --grey-100: #eaedf3;
  --grey-200: #e2e7ef;
  --grey-300: #dbe0ea;
  --grey-400: #d3dae6;
  --grey-500: #cbd3e1;
  --grey-600: #adb3bf;
  --grey-700: #8e949e;
  --grey-800: #70747c;
  --grey-900: #51545a;
  --grey-1000: #333538;
  --grey-1100: #141516;
  /* Purple */
  --purple-0: #f5ecfe;
  --purple-100: #ebddfe;
  --purple-200: #e0cefe;
  --purple-300: #d6bffe;
  --purple-400: #cbaffe;
  --purple-500: #c1a0fe;
  --purple-600: #b691fe;
  --purple-700: #ac82fe;
  --purple-800: #a77afe;
  --purple-900: #a172fd;

  /* pink */
  --pink-100: #fff0f3;
  --pink-200: #ffccd5;
  --pink-300: #ffb3c1;
  --pink-400: #ff8fa3;
  --pink-500: #ff758f;
  --pink-600: #ff4d6d;
  --pink-700: #c9184a;
  --pink-800: #a4133c;
  --pink-900: #800f2f;
  --pink-1000: #590d22;

  /* rose gold */
  --roseGold-100: #d0a1a8;
  --roseGold-200: #c89098;
  --roseGold-300: #bf7f89;
  --roseGold-400: #b76e79;
  --roseGold-500: #af5d69;
  --roseGold-600: #a2505d;
  --roseGold-700: #914853;

  /* rest */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* box shadows */
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-5: 0px 0px 10px rgba(0, 0, 0, 0.01),
    0 10px 10px -5px rgba(0, 0, 0, 0.03);
  --shadow-6: 0px 2px 5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0);
  /* typography */
  --defaultFontSize: 87.5%;
  --headingFont: "Noto Sans Display", monospace;
  --bodyFont: "Noto Sans Display", sans-serif;
  --Oswald: "Oswald";
  --smallText: 0.8em;
  --extraSmallText: 0.7em;
  /* rest */
  --backgroundColor: var(--grey-0);
  --textColor: var(--grey-1000);
  --borderRadius: 0.2rem;
  --borderRadius2: 0.8rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1520px;
  --fixed-width: 600px;
}

.Oswald {
  font-family: var(--Oswald);
}

html {
  scroll-behavior: smooth;
}

input[type="date"]::before {
  color: #999999;
  content: attr(placeholder);
}
input[type="date"] {
  color: #ffffff;
}
input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "" !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0 !important;
}
*:focus {
  outline: 0;
}

/* Hero  */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 550px;
}
.hero {
  position: relative;
  height: 100%;
}

.hero img {
  height: 100%;
  transition: var(--transition);
  /* object-fit: cover; */
}
.info {
  position: absolute;
  top: 40%;
  left: 10%;
}
.arrow-left {
  position: absolute;
  top: 45%;
  left: 0;
  font-size: 2rem;
  padding: 15px;
  background-color: white;
  color: gray;
  transition: var(--transition);
}
.arrow-left:hover,
.arrow-right:hover {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: var(--roseGold-700);
}
.arrow-right {
  position: absolute;
  top: 45%;
  right: 0;
  font-size: 2rem;
  padding: 15px;
  background-color: white;
  color: gray;
  transition: var(--transition);
}
.image-list {
  display: flex;
  flex-direction: row;
  /* height: 100%; */
  overflow-y: scroll;
  width: 100%;
  margin-top: 6px;
}
.image-list img {
  object-fit: cover;
  object-position: center;
  position: relative;
  width: 100%;
  height: 70px;
  margin-right: 2px;
}
.image-list img:hover {
  cursor: pointer;
}

/* Hero Banner Media Scaling */
@media (min-width: 640px) {
  .container {
    flex-direction: row;
    max-width: 100%;
    max-height: 500px;
    margin: 0 auto;
  }
  .hero {
    margin-right: 10px;
  }
  .image-list {
    flex-direction: column;
    width: 510px;
    height: 100%;
  }
  .image-list img {
    width: 100%;
    height: 132px;
  }
  .image-single {
    border-left: 1px solid var(--grey-600);
    transition: var(--transition);
    filter: grayscale(80%);
  }
  /* Navigation */
  .triangle-down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--pink-500);
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 100%;
    height: 700px;
  }
  .image-list img {
    height: 140px;
  }
}

/* About Page */
.about-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
  margin: 0 auto;
  justify-items: center;
}
.about-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.about-img {
  width: 350px;
  position: absolute;
  /* top: ; */
}

/* Hair Care */
.hair-care-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  /* height: 300px; */
  margin-top: 30px;
}
.hair-care-arrow-left {
  width: 60px;
  font-size: 1.5rem;
  padding: 15px;
  background-color: white;
  color: gray;
  transition: var(--transition);
  border: 1px solid var(--grey-200);
}
.hair-care-arrow-left:hover,
.hair-care-arrow-right:hover {
  cursor: pointer;
  color: var(--roseGold-700);
}
.hair-care-arrow-right {
  width: 60px;
  font-size: 1.5rem;
  padding: 15px;
  background-color: white;
  color: gray;
  transition: var(--transition);
  border: 1px solid var(--grey-200);
}
.hair-care-image-list {
  position: relative;
  display: flex;
  flex-direction: row;
  /* overflow-x: scroll; */
  width: 100%;
  margin-top: 6px;
  scroll-behavior: smooth;
}

@media (min-width: 640px) {
  .hair-care-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    height: 300px;
    /* margin-top: 40px; */
  }
}

/* Shipping form style validation */
.user-email-input {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  /* border-width: 0px; */
}
.user-email-input::placeholder {
  /* font-style: italic; */
  font-size: 12px;
  color: var(--grey-900);
}
.input-error {
  border-width: 1px;
  border-color: var(--red-dark);
}
.user-email-input-error {
  margin-top: 10px;
  font-size: 16px;
  color: var(--red-dark);
}
.email-verify {
  font-size: 16px;
  margin-top: 10px;
  color: var(--grey-600);
}
.btn {
  cursor: pointer;
  appearance: none;
  color: var(--primary-700);
  border: none;
  border-radius: 3px;
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-5);
  transition: var(--transition);
  text-transform: capitalize;
}
.total_btn {
  background-color: var(--grey-100);
  color: var(--grey-1100);
  font-size: 14px;
}
.total-button {
  margin-top: 30px;
  display: flex;
  /* width: 290px; */
  justify-content: center;
}

/* .hair-care-image-list h1 {
	position: absolute;

} */

/* Hide scrollbar for Chrome, Safari and Opera */
.general-scrollbar-hide::-webkit-scrollbar,
.single::-webkit-scrollbar {
  display: none;
}
.general-scrollbar-hide::-webkit-scrollbar,
.single::-webkit-scrollbar {
  display: none;
}
.image-list::-webkit-scrollbar,
.single::-webkit-scrollbar {
  display: none;
}
.hair-care-image-list::-webkit-scrollbar,
.single::-webkit-scrollbar {
  display: none;
}
/* width height */
.featuredCard::-webkit-scrollbar {
  height: 10px;
  width: 9px;
}
.slimBar::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.featuredCard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(212, 212, 212);
  border-radius: 100px;
}

/* Handle */
.featuredCard::-webkit-scrollbar-thumb {
  background: rgba(219, 219, 219, 1);
  border-radius: 100px;
}
.slimBar::-webkit-scrollbar-thumb {
  background: rgba(50, 50, 50, 0.4);
  border-radius: 100px;
}

/* Handle on hover */
.featuredCard::-webkit-scrollbar-thumb:hover {
  background: rgba(50, 50, 50, 0.4);
}
/* width height */
.featuredCard2::-webkit-scrollbar {
  height: 10px;
  width: 8px;
}
/* Handle on hover */
.featuredCard2::-webkit-scrollbar-thumb {
  background: rgba(43, 43, 43, 0.8);
  border-radius: 100px;
}

/* Handle on hover */
.featuredCard2::-webkit-scrollbar-thumb:hover {
  background: rgba(78, 78, 78, 0.8);
}

/* Hide scrollbar for IE, Edge and Firefox */
.image-list,
.hair-care-image-list,
.single {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rose-gold {
  color: var(--roseGold-400);
}

/* Search */
.search {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%;
}
.search form {
  height: 40px;
}
.input {
  border-radius: 3px;
  height: 100%;
  background-color: transparent;
  box-shadow: var(--shadow-3);
  display: flex;
  align-items: center;
}
.input input {
  outline: none !important;
  border: none !important;
  flex: 0.9;
  height: 100%;
  border-right: 1px solid var(--gold-200);
}
.input svg {
  flex: 0.1;
  color: var(--grey-800);
  cursor: pointer;
}
.input input::placeholder {
  font-size: 14px;
}

/* Bookings */
label {
  position: relative;
  margin-top: 2px;
}
label > input {
  font-size: 1.1rem;
  color: var(--grey-1000);
  font-weight: 300;
  border: none;
  outline: none;
  width: 20.7rem;
  border-radius: 3px !important;
}

label p {
  color: var(--grey-800);
  font-size: 0.8rem;
  pointer-events: none;
  position: absolute;
  top: 25%;
  margin-left: 3px;
  padding: 0 0.5rem;
  background: #fff;
  transition: transform 0.5s ease-in-out;
  /* transition-property: top, font-size, color;
	transition-duration: 0.5s;
	transition-timing-function: ease-in-out; */
  border-radius: 3px;
}

label input:focus + p,
input:not(:placeholder-shown) + p {
  transform: translateY(-1.2rem) scale(0.8);
  margin-left: 3px;
  color: #002fff;
}

label input::placeholder {
  color: transparent;
}

/* Shipment */
.top {
  max-width: 100%;
  margin: 0 auto;
}
.top > table {
  background-color: white;
}
.table-head-row {
  background-color: var(--grey-300);
  width: 100vw;
  margin: 0 auto;
}
.table-head-item {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid var(--grey-600);
  border-left: 1px solid var(--grey-600);
  text-transform: capitalize;
}
.table-item-row {
  position: relative;
}
.table-items {
  border: 1px solid var(--grey-400);
  text-align: top;
  font-size: 14px;
}
.align-left {
  text-align: left;
}
.table-items span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--primary-600);
  font-weight: 500;
  font-size: 10px;
  cursor: pointer;
  text-transform: uppercase;
}
.table-items span:hover {
  color: var(--primary-800);
}
.table-items svg {
  display: inline;
  font-size: 24px;
}
.been-shipped {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.95);
}
.been-shipped-textsm {
  font-size: 16px;
  text-transform: capitalize !important;
  font-weight: 400 !important;
}
.been-shipped button {
  color: var(--grey-700);
  text-transform: uppercase;
  cursor: not-allowed;
}
.been-shipped span {
  color: var(--black);
  text-transform: uppercase;
  font-weight: bold;
}
.been-shipped button:hover {
  color: var(--black);
}

.checkout-error {
  display: flex;
  flex-direction: row;
  color: var(--red-dark);
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: italic;
}
.history-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0px;
  width: 95%;
  overflow-x: auto;
}
.progress {
  height: 2px;
  width: 80%;
  margin: 0 auto;
  background-color: var(--pink-700);
  position: absolute;
  overflow: hidden;
}
.progress .inner {
  height: 100%;
  background-color: var(--pink-100);
  position: absolute;
  animation: progress 1s infinite;
}
@keyframes progress {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 105%;
    width: 75%;
  }
}

@media screen and (min-width: 850px) {
  .top {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .bg-blur {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .bg-blur2 {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.6);
  }
  .bg-blur3 {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
  }
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .service-info {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
  }
  .been-shipped {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.5);
  }
  .main-page-login {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
  }
  .category-tags {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.7);
  }
}
.arrows-wrapper {
  position: relative;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
}
.arrow {
  display: inline-block;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 7px;
  border: solid rgb(255, 255, 255);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.arrow:nth-child(2) {
  animation: slide-down1 1.6s ease-in infinite;
}
.arrow:nth-child(3) {
  animation: slide-down2 1.6s ease-in infinite;
}
@keyframes slide-down1 {
  0% {
    opacity: 0;
    top: 10%;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 60%;
  }
}
@keyframes slide-down2 {
  0% {
    opacity: 0;
    top: 20%;
  }
  50% {
    opacity: 0.5;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 120%;
  }
}

.home {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.85)
    ),
    url("https://res.cloudinary.com/dhdccvkyt/image/upload/v1660144213/modelest-upload/wigs/eryka2_eayihm.jpg");
  /* url("https://res.cloudinary.com/dhdccvkyt/image/upload/v1653721553/modelest-upload/general/about2_wsiqfc.jpg"); */
}
.logo {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.85)
    ),
    url("https://res.cloudinary.com/dhdccvkyt/image/upload/v1662354918/modelest-upload/general/logo2_iusqsn.png");
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 630px;
}
.slide {
  opacity: 1;
  transition-duration: 2s ease;
  transform: scale(0.8);
  overflow: hidden;
}
.image {
  border-radius: 10px;
  max-height: 420px;
}
.image2 {
  border-radius: 10px;
  max-height: 420px;
}
.right-arrow {
  position: absolute;
  top: 40%;
  font-size: 1.8rem;
  color: #946a0f;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.left-arrow {
  position: absolute;
  top: 40%;
  font-size: 1.8rem;
  color: #946a0f;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slider .active {
  transform: scale(1);
  animation: slide-animate 1s ease;
}
@keyframes slide-animate {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pagWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pagNum {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: rgb(193, 193, 193);
}
.pagButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  font-size: 28px;
  background-color: #fff;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(134, 134, 134);
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.5s ease;
}
@media (max-width: 500px) {
  .pagButton {
    width: 35px;
    height: 35px;
  }
}
.flipArrow {
  transform: rotate(180deg);
}
.active {
  color: rgb(199, 0, 0);
  transition: background 0.5s ease;
}
.card {
  width: 350px;
  height: 450px;
  background: #000;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.25s ease;
  backface-visibility: hidden;
}
.card:hover {
  transform: scale(0.9);
}
.card:hover:after {
  height: 280px;
}
.card:hover .con-text p {
  margin-bottom: 30px;
  margin-top: 10px;
  opacity: 1;
}
.card:hover .con-text h2 {
  margin-bottom: 0px;
}
.card .con-text h2 {
  font-size: 20px;
  margin-bottom: 30px;
}
.card:hover button {
  opacity: 1;
}
.card:hover img {
  transform: scale(1.25);
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}
.card:hover .ul {
  transform: translate(0);
  opacity: 1;
}
.card:after {
  width: 100%;
  content: "";
  left: 0px;
  bottom: 0px;
  height: 150px;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 100%));
  z-index: 20;
  transition: all 0.25s ease;
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  transition: all 0.25s ease;
}
.card .con-text {
  z-index: 10;
  position: absolute;
  bottom: 0px;
  color: rgb(0, 0, 0);
  padding: 20px;
  padding-bottom: 40px;
}
.card .con-text p {
  color: rgb(0, 0, 0);
  font-size: 0.8rem;
  opacity: 0;
  margin-bottom: -170px;
  margin-top: 0px;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.card button {
  padding: 2px 17px;
  border-radius: 8px;
  background: transparent;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  margin-top: 10px;
  opacity: 0;
  cursor: pointer;
  transition: all 0.25s ease;
  font-size: 1.2rem;
  font-weight: bold;
  outline: none;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.card button:hover {
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.ul {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 40;
  border-radius: 14px;
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  top: 0px;
  opacity: 0;
  transform: translate(100%);
  transition: all 0.25s ease;
}
.ul li {
  background: #fff;
  list-style: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: all 0.25s ease;
  backface-visibility: hidden;
}
/* .ul li:last-child {
	border-radius: 0px 0px 12px 12px;
}
.ul li:first-child {
	border-radius: 12px 12px 0px 0px;
} */
.ul li:hover {
  opacity: 1;
  transform: translate(-7px, -4px);
  border-radius: 6px;
}
.ul li svg {
  font-size: 1.4rem;
  color: #000;
}

.content {
  width: 30vw;
  padding: 1em;
}
._search {
  /* border-top-right-radius: 30px; */
  /* border-bottom-right-radius: 30px; */
  /* margin-left: 30px; */
  --size: 40px;
  border: 1px solid rgb(0, 0, 0);
  display: flex;
  /* border-radius: 100px; */
  overflow: hidden;
  font-size: 1.25em;
  position: relative;
  width: var(--size);
  height: var(--size);
  transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.search__input {
  border: 0;
  padding: 0.25em 1em;
  flex-grow: 1;
  outline: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  background: transparent;
}
.search__submit {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: rgb(218, 218, 218);
  cursor: pointer;
  transition: background 200ms ease-out;
  width: var(--size);
  height: var(--size);
  color: #000000;
  /* border-left: 1px solid black; */
}
.__search {
  width: 80%;
}
.__search__input {
  opacity: 1;
  z-index: initial;
  cursor: initial;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.media-scroller {
  --spacer: 2rem;
  --spacer1: 1rem;
  --spacer2: 0.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 26%;
  /* padding: 0 var(--spacer1); */
  overflow-x: scroll;
  overscroll-behavior-inline: contain;
  color: white;
}
@media (max-width: 1000px) {
  .media-scroller {
    grid-auto-columns: 35%;
  }
}
@media (max-width: 640px) {
  .media-scroller {
    grid-auto-columns: 55%;
  }
}

.media-element {
  display: grid;
  grid-template-rows: min-content;
  gap: var(--spacer2);
  padding: var(--spacer2);
  background: rgb(255, 255, 255);
  border-radius: 10px;
}
.media-element > img {
  border-radius: 10px;
  inline-size: 100%;
  aspect-ratio: 12 /9;
  object-fit: cover;
}

.circular-loader {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top: #000;
  animation: circular-loader 2s linear infinite;
}
.circular-loader-small {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid rgb(0, 0, 0);
  border-top: #000;
  animation: circular-loader 2s linear infinite;
}
.circular-loader-payment {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #000;
  border-top: #000;
  animation: circular-loader 2s linear infinite;
}
@keyframes circular-loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CHECKBOX The container */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  /* top: 0; */
  left: 0;
  margin-top: 3px;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid rgb(65, 65, 65);
}
.checkmark2 {
  position: absolute;
  /* top: 0; */
  left: 0;
  margin-top: 3px;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid #bba371;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.check-container:hover input ~ .checkmark2 {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: black;
}
.check-container input:checked ~ .checkmark2 {
  background-color: #bba371;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkmark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}
.check-container input:checked ~ .checkmark2:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-container .checkmark2:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark-radio {
  background-color: #bba371;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark-radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.scaling {
  -webkit-animation: 4s linear 0s infinite alternate move_eye;
  animation: 4s linear 0s infinite alternate move_eye;
}

@-webkit-keyframes move_eye {
  from {
    transform: scale(1);
    color: #00ccff;
  }
  to {
    color: #ff00d9;
    transform: scale(1.2);
  }
}
@keyframes move_eye {
  from {
    color: #00ccff;
    transform: scale(1);
  }
  to {
    color: #ff00d9;
    transform: scale(1.2);
  }
}
.scaling2 {
  -webkit-animation: 4s linear 0s infinite alternate move_eye2;
  animation: 4s linear 0s infinite alternate move_eye2;
}

@-webkit-keyframes move_eye2 {
  from {
    transform: scale(1);
    color: #00ccff;
  }
  to {
    color: #ff00d9;
    transform: scale(1.2);
  }
}
@keyframes move_eye2 {
  from {
    color: #00ccff;
    transform: scale(1);
  }
  to {
    color: #ff00d9;
    transform: scale(1.2);
  }
}

/*  Yellow text highlight */
p.yellow::selection {
  background: #ca8000;
  color: black;
}
p.yellow::-moz-selection {
  color: black;
  background: #ca8000;
}

.box {
  position: relative;
  width: 180px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.box::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #00ccff
  );
  animation: animate 5s linear infinite;
  animation-delay: -2.5s;
  border-radius: 10px;
}
.box::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  background-image: conic-gradient(
    transparent,
    transparent,
    transparent,
    #ff00d9
  );
  animation: animate 5s linear infinite;
  border-radius: 10px;
}
@keyframes animate {
  100% {
    transform: rotate(360deg);
  }
}
.box span {
  inset: 1px;
  background: #0c1022;
  position: absolute;
  border-radius: 10px;
  z-index: 2;
}
.box .content {
  position: relative;
  z-index: 3;
  color: #fff;
  text-align: center;
}
.box .content h2 {
  font-size: 5em;
}
.box .content h5 {
  font-size: 1.2em;
}

/* banner images */
/* .swiper {
	padding-top: 30px;
	margin-bottom: 30px;
} */

.swiper-slide {
  background-position: center;
  background-size: cover;
  -webkit-box-reflect: below 1px
    linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.1));
}

.swiper-slide img {
  display: block;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
